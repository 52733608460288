import React from 'react';
import 'normalize.css';
import 'css/app.css';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from 'modules/redux-store';
import { AppLayout, Loading } from 'components';
import { PersistGate } from 'redux-persist/integration/react';
import { useInitGoogleMap } from 'hooks';
import { MapPreview, ErrorGeneral } from 'views';
import { AppRoutes } from 'const';

export const App: React.FC = () => {
  const persistedStore = configureStore();
  const { store, persistor } = persistedStore;

  const [mapReady] = useInitGoogleMap();

  /**
   * Return App components
   */
  return (
    <Provider store={store}>
      <PersistGate
        loading={<Loading fullPage isLoading />}
        persistor={persistor}
      >
        <Loading fullPage isLoading={!mapReady}>
          <BrowserRouter>
            <AppLayout>
              <Switch>
                <Route path="/">
                  <MapPreview />
                </Route>
                <Route exact path={AppRoutes.SpecificLocation}>
                  <MapPreview />
                </Route>
                <Route component={ErrorGeneral} />
              </Switch>
            </AppLayout>
          </BrowserRouter>
        </Loading>
      </PersistGate>
    </Provider>
  );
};
