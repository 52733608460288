import React from 'react';

export const AppLayout: React.FC = ({ children }) => (
  <div className="container">
    <div className="masterlayout">
      <div className="masterlayout__map">
        <main>{children}</main>
      </div>
    </div>
  </div>
);
