export const clusterOptions = () => {
  return {
    gridSize: 100,
    minimumClusterSize: 7,
    styles: [
      {
        textColor: 'black',
        textSize: 12,
        url: require('assets/images/marker-clusters/m2.png'),
        height: 44,
        width: 32
      }
    ]
  };
};
