// Marker icons for Žito and Dobro
const dobroMarker = require('assets/images/icons/Dobro - map icons_Dobro icon.png');
const zitoMarker = require('assets/images/icons/Dobro - map icons_Žito d.o.o. icon - clear.svg');
const bioplinMarker = require('assets/images/icons/Dobro - map icons_Bioplinska postrojenja icon (1).svg');
const sokolMarker = require('assets/images/icons/Dobro - map icons_Centar Sokol d.o.o. icon (1).svg');
const kokeMarker = require('assets/images/icons/Dobro - map icons_Koke nesilice icon (1).svg');
const kooperacijaMarker = require('assets/images/icons/Dobro - map icons_Kooperacija i partneri icon (1).svg');
const maloprodajaMarker = require('assets/images/icons/Dobro - map icons_Maloprodaja icon (1).svg');
const mesnaMarker = require('assets/images/icons/Dobro - Map icons_Mesna industrija icon.png');
const mlinDakovoMarker = require('assets/images/icons/Dobro - map icons_Mlin Đakovo icon (1).svg');
const muzneKraveMarker = require('assets/images/icons/Dobro - map icons_Muzne krave icon (1).svg');
const pakirniCentarMarker = require('assets/images/icons/Dobro - map icons_Parkirni centar za jaja icon (1).svg');
const povrsineMarker = require('assets/images/icons/Dobro - map icons_Površine icon (1).svg');
const silosiMarker = require('assets/images/icons/Dobro - map icons_Silosni kapacitet icon (1).svg');
const svinjogojstvoMarker = require('assets/images/icons/Dobro - map icons_Svinjogojstvo icon (1).svg');
const terranegraMarker = require('assets/images/icons/TerraNegra_Favicon.png');
const tovnaJunadMarker = require('assets/images/icons/Dobro - map icons_Tovna junad icon (1).svg');
const secerOsijekMarker = require('assets/images/icons/Dobro - map icons_Tvornica šećera Osijek icon (1).svg');
const stocnaHranaMarker = require('assets/images/icons/Dobro - map icons_Tvornica stočne hrane icon (1).svg');
const uljeCepinMarker = require('assets/images/icons/Dobro - map icons_Tvornica ulja Čepin icon (1).svg');

export const MarkerIcons: { [key: string]: any } = {
  Dobro: dobroMarker,
  Žito: zitoMarker,
  // Ratarstvo icon
  'Novi Agrar d.o.o.': povrsineMarker,
  'Ratarstvo Ovčara': povrsineMarker,
  'Ratarstvo Dalj': povrsineMarker,
  'Ratarstvo Koška': povrsineMarker,
  'Ratarstvo Melem': povrsineMarker,
  'Ratarstvo Klisa': povrsineMarker,
  'Ratarstvo Ratkovo': povrsineMarker,
  'Ratarstvo Rosa': povrsineMarker,
  'Ratarstvo Seleš': povrsineMarker,
  // Kooperacija icon
  'Kooperacija Dalj': kooperacijaMarker,
  'Kooperacija Đakovo': kooperacijaMarker,
  'Kooperacija Šodolovci': kooperacijaMarker,
  'Kooperacija Koška': kooperacijaMarker,
  'Kooperacija Slatina': kooperacijaMarker,
  'Kooperacija Drenovci': kooperacijaMarker,
  'Kooperacija Gradište': kooperacijaMarker,
  'Kooperacija Lipovac': kooperacijaMarker,
  'Kooperacija Čađavica': kooperacijaMarker,
  'Kooperacija Šljivoševci': kooperacijaMarker,
  'Kooperacija Čepin': kooperacijaMarker,
  'Kooperacija Kneževi Vinogradi': kooperacijaMarker,
  'Kooperacija Drenje': kooperacijaMarker,
  'Kooperacija Velika Kopanica': kooperacijaMarker,
  'Kooperacija Gorjani': kooperacijaMarker,
  // Maloprodaja icon
  'Robna kuća Sjenjak': maloprodajaMarker,
  'Maloprodaja DOBRO Zagreb': maloprodajaMarker,
  'Maloprodaja Slatina': maloprodajaMarker,
  'Maloprodaja Donji Miholjac': maloprodajaMarker,
  'Maloprodaja DOBRO Osijek': maloprodajaMarker,
  'Maloprodaja Piškorevci': maloprodajaMarker,
  'Maloprodaja Đakovo': maloprodajaMarker,
  // Silosi icon
  'Silos Đakovo': silosiMarker,
  'Silos Slatina': silosiMarker,
  'Silos Šljivoševci': silosiMarker,
  'Silos Županja': silosiMarker,
  'Silos Osijek': silosiMarker,
  'Silos Vrbanja': silosiMarker,
  'Silos Čađavica': silosiMarker,
  'Silos Drenovci': silosiMarker,
  // Svinjogojstvo icon
  'Svinjogojska farma Slašćak': svinjogojstvoMarker,
  'Svinjogojska farma Velika Branjevina': svinjogojstvoMarker,
  'Svinjogojska farma Stari Seleš': svinjogojstvoMarker,
  'Svinjogojska farma Prkos': svinjogojstvoMarker,
  'Svinjogojska farma Magadenovac': svinjogojstvoMarker,
  'Svinjogojska farma Forkuševci': svinjogojstvoMarker,
  'Svinjogojska farma Lužani': svinjogojstvoMarker,
  'Svinjogojska farma Lipovača': svinjogojstvoMarker,
  // Muzne krave icon
  'Farma muznih krava Orlovnjak': muzneKraveMarker,
  'Farma muznih krava Mala Branjevina': muzneKraveMarker,
  // Tovna junad
  'Farma tovne junadi Ankin dvor': tovnaJunadMarker,
  'Farma tovne junadi Klisa': tovnaJunadMarker,
  // Koke nesilice
  'Farma koka nesilica Vuka': kokeMarker,
  // Pakirni centar za jaja
  'Pakirni centar za jaja': pakirniCentarMarker,
  // Bioplinska postrojenja
  'Bioplinsko postrojenje Orlovnjak': bioplinMarker,
  'Bioplinsko postrojenje Mala Branjevina 2': bioplinMarker,
  'Bioplinsko postrojenje Klisa': bioplinMarker,
  'Bioplinsko postrojenje Mala Branjevina 1': bioplinMarker,
  // Mesna industrija icon
  'Žito d.o.o. – Pogon za narezivanje i pakiranje trajnih mesnih proizvoda': mesnaMarker,
  'Prosciutto Pannonico d.o.o.': mesnaMarker,
  'Salami Aurea d.o.o.': mesnaMarker,
  'Copadio d.o.o.': mesnaMarker,
  // Tvornica ulja Čepin
  'Tvornica ulja Čepin d.d.': uljeCepinMarker,
  // Tvornica šećera Osijek
  'Tvornica šećera Osijek d.o.o.': secerOsijekMarker,
  // Žito d.o.o
  'Žito d.o.o.': zitoMarker,
  // Tvornica stočne hrane Vitalka
  'Tvornica stočne hrane Vitalka': stocnaHranaMarker,
  // Centar Sokol d.o.o.
  'Centar Sokol d.o.o.': sokolMarker,
  // Mlin Đakovo
  'Mlin Đakovo': mlinDakovoMarker,
  // Terra Negra Country Club
  'Terra Negra Country Club': terranegraMarker
};
