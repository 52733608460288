import { ApplicationState } from 'modules/redux-store';
import { FirestoreListenerActions } from 'modules/firebase';
import { Dispatch } from 'redux';

import { CollectionLastModified } from '../models';
import { LastModifiedService } from '../services/LastModifiedService';

import { LastModifiedActions } from './actions';

const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  /** If listener is already active, don't trigger again */
  const lastModifiedListener = getState().listeners.lastModifiedListener;
  if (lastModifiedListener) {
    return;
  }

  dispatch(LastModifiedActions.addRequest());

  const successFunction = (data: CollectionLastModified[]) => {
    dispatch(LastModifiedActions.addLastModified(data));
  };

  const errorFunction = (error: string) =>
    dispatch(LastModifiedActions.addError(error));

  const listenerProps = {
    successFunction,
    errorFunction
  };

  LastModifiedService.filterAsync(undefined, listenerProps).then(listener => {
    if (typeof listener === 'function') {
      dispatch(
        FirestoreListenerActions.setListener('lastModifiedListener', listener)
      );
    }
  });

  return;
};

export const LastModifiedThunks = {
  getAllAsync
};
