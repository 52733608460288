import { useState, useEffect, useCallback, useRef } from 'react';
import { Location } from 'modules/locations';
import MarkerClusterer from '@google/markerclusterer';
import { MarkerIcons } from 'const';

import { mapStyles, clusterOptions } from '../consts';
import { getContentString } from '../helpers';

declare const google: any;

export const useMap = (
  locations: Location[],
  loader: boolean,
  activeListItem?: string,
  locationId?: string,
  markerId?: string
): [
  google.maps.Map | undefined,
  React.MutableRefObject<
    Array<{
      id: string | undefined;
      marker: google.maps.Marker;
    }>
  >
] => {
  const [mapState, setMapState] = useState<google.maps.Map>();
  const markers: Array<{
    id: string | undefined;
    marker: google.maps.Marker;
  }> = [];
  const markersRef = useRef(markers);
  const infowindow: google.maps.InfoWindow = new google.maps.InfoWindow({
    content: ''
  });
  const infowindowRef = useRef(infowindow);

  const removeMarkers = useCallback(() => {
    markersRef.current.forEach(marker => {
      marker.marker.setMap(null);
    });

    markersRef.current = [];
  }, []);

  /** Adding listener for closing infoWindow on map click */
  useEffect(() => {
    if (!mapState) return;

    const mapListener: google.maps.MapsEventListener = google.maps.event.addListener(
      mapState,
      'click',
      () => {
        if (infowindowRef.current) {
          infowindowRef.current.close();
        }
      }
    );

    return () => {
      mapListener.remove();
    };
  }, [mapState]);

  const mapDataToMap = useCallback(() => {
    const markersArray: Array<{
      id: string | undefined;
      marker: google.maps.Marker;
    }> = [];

    locations.forEach(location => {
      const coords = location.position;
      const latLng: google.maps.LatLng = new google.maps.LatLng(
        Number(coords.lat),
        Number(coords.lng)
      );

      const icon = {
        url: MarkerIcons[location.storeName] || MarkerIcons[location.company],
        scaledSize: new google.maps.Size(28, 28),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(14, 28)
      };

      const marker: google.maps.Marker = new google.maps.Marker({
        position: latLng,
        icon,
        map: mapState
      });

      google.maps.event.addListener(marker, 'click', () => {
        if (infowindowRef.current) {
          infowindowRef.current.close();
        }

        infowindowRef.current = new google.maps.InfoWindow({
          content: getContentString(location)
        });

        if (infowindowRef.current) {
          infowindowRef.current.open(mapState, marker);
        }
      });

      markersArray.push({ id: location.id, marker });
    });

    // zoom in/out to cover all the markers on the map
    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    markersArray.forEach(mark => {
      bounds.extend(
        mark.marker.getPosition() ||
          new google.maps.LatLng(44.7076475, 16.4978158)
      );
    });

    if (mapState && !locationId) {
      mapState.fitBounds(bounds);
    }

    // tslint:disable-next-line: no-unused-expression
    new MarkerClusterer(
      mapState,
      markersArray.map(item => item.marker),
      clusterOptions()
    );

    markersRef.current = markersArray;
  }, [locations, mapState, locationId]);

  useEffect(() => {
    if (loader) {
      return;
    }

    if (!mapState) {
      const map: google.maps.Map = new google.maps.Map(
        document.getElementById('map-preview'),
        {
          center: {
            lat: 44.7076475,
            lng: 16.4978158
          },
          zoom: 8,
          draggableCursor: 'default',
          mapTypeControl: false,
          styles: mapStyles,
          minZoom: 8,
          maxZoom: 18
        }
      );

      setMapState(map);
    }
  }, [mapState, loader]);

  useEffect(() => {
    if (
      mapState &&
      !locationId &&
      (locations.length === 0 || !activeListItem)
    ) {
      const latLng: google.maps.LatLng = new google.maps.LatLng(
        44.7076475,
        16.4978158
      );
      mapState.panTo(latLng);
      mapState.setZoom(17);
      return;
    }

    if (mapState && locationId) {
      const [lat, lng, zoom] = locationId.split('&');

      if (lat && lng && zoom) {
        const latLng: google.maps.LatLng = new google.maps.LatLng(lat, lng);
        mapState.panTo(latLng);
        mapState.setZoom(Number(zoom));
      }
    }
  }, [locations.length, mapState, activeListItem, locationId]);

  useEffect(() => {
    if (locations && markerId) {
      const selectedLocation = locations.find(
        loc => loc.id === markerId //'JmT2jK1tvsu29w0ohpRw'
      );
      if (!selectedLocation) return;

      const coords = selectedLocation.position;
      const latLng: google.maps.LatLng = new google.maps.LatLng(
        Number(coords.lat),
        Number(coords.lng)
      );
      const icon = {
        url:
          MarkerIcons[selectedLocation.storeName] ||
          MarkerIcons[selectedLocation.company],
        scaledSize: new google.maps.Size(28, 28),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(14, 28)
      };

      const marker: google.maps.Marker = new google.maps.Marker({
        position: latLng,
        icon,
        map: mapState
      });

      infowindowRef.current = new google.maps.InfoWindow({
        content: getContentString(selectedLocation)
      });
      infowindowRef.current.open(mapState, marker);
    }
  }, [locations, mapState, markerId]);

  useEffect(() => {
    if (!mapState) {
      return;
    }

    removeMarkers();

    if (locations.length) {
      mapDataToMap();
    }
  }, [mapState, mapDataToMap, removeMarkers, locations.length]);

  return [mapState, markersRef];
};
