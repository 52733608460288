import React from 'react';
import { SearchSelectors } from 'modules/search';
import { ApplicationState } from 'modules/redux-store';
import { connect } from 'react-redux';
import {
  LocationsThunks,
  Location,
  Company,
  LocationsActions,
  LocationsSelectors
} from 'modules/locations';
import { Loading } from 'components';
import { useMap } from 'modules/map';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import {
  CollectionLastModified,
  useLastModified,
  LastModifiedThunks
} from 'modules/lastModified';
import { FirebaseCollection, AppRoutes } from 'const';
import { useLocation, useRouteMatch } from 'react-router-dom';

interface DispatchProps {
  getAll: VoidFunction;
  changeCity: (city: string | null) => void;
  getLastModified: VoidFunction;
  changeLocationsLoading: VoidFunction;
}

interface ReduxProps {
  locations: Location[];
  error?: string;
  locationsAreChanging: boolean;
  cityFilterOptions: Array<{ value: string; label: string }>;
  cityFilter: string | null;
  lastModified: CollectionLastModified[];
  lastModifiedChanging: boolean;
}

type Props = DispatchProps & ReduxProps;

const MapPreview: React.FC<Props> = ({
  locations,
  locationsAreChanging,
  getAll,
  cityFilterOptions,
  cityFilter,
  changeCity,
  lastModified,
  getLastModified,
  changeLocationsLoading,
  lastModifiedChanging
}) => {
  const { pathname } = useLocation();
  const locationMatch = useRouteMatch(AppRoutes.SpecificLocation);
  const markerMatch = useRouteMatch(AppRoutes.SpecificMarker);
  const resolvedMatch = markerMatch || locationMatch;

  useLastModified(
    getLastModified,
    lastModified.find(doc => doc.id === FirebaseCollection.Locations),
    getAll,
    lastModifiedChanging,
    changeLocationsLoading
  );

  useMap(
    locations,
    locationsAreChanging,
    '',
    resolvedMatch ? resolvedMatch.params.locationId : undefined,
    resolvedMatch ? resolvedMatch.params.markerId : undefined
  );

  return (
    <section className="preview" id="preview">
      {pathname.startsWith(AppRoutes.Hidden) ? null : (
        <header className="preview__header">
          <div className="preview__header__branding">
            <img src={require('assets/images/logo_zito.png')} alt="Žito" />
          </div>
          <div className="preview__header__copy">
            <p>Poslovne jedinice</p>
          </div>
        </header>
      )}

      <Loading fullPage isLoading={locationsAreChanging}>
        {(!resolvedMatch || !resolvedMatch.params.locationId) && (
          <div
            className={`preview__filters${
              pathname === AppRoutes.Hidden ? ' preview__filters--top' : ''
            }`}
          >
            <div className="preview__filters__city">
              <Select
                value={
                  cityFilter
                    ? cityFilterOptions.find(
                        option => option.value === cityFilter
                      )
                    : null
                }
                onChange={handleCityChange}
                options={cityFilterOptions}
                placeholder="Odaberite mjesto"
                isClearable
                id="city_filter"
                className="input--select"
              />
            </div>
          </div>
        )}

        <div className="map-preview" id="map-preview" />
      </Loading>
    </section>
  );

  function handleCityChange(
    value: ValueType<{
      value: string;
      label: string;
    }> | null
  ) {
    if (value) {
      changeCity((value as any).value);
      return;
    }

    changeCity(null);
  }
};

export default connect<ReduxProps, DispatchProps, {}, ApplicationState>(
  state => ({
    locations: LocationsSelectors.sortByCity(state).filter(
      loc => loc.company === Company.Zito
    ),
    error: state.locations.error,
    locationsAreChanging: state.locations.locationsAreChanging,
    cityFilterOptions: SearchSelectors.ZitoCityFilterOptions(state),
    cityFilter: state.locations.cityFilter,
    lastModified: state.lastModified.lastModified,
    lastModifiedChanging: state.lastModified.isChanging
  }),
  {
    getAll: LocationsThunks.getAllAsync,
    changeCity: LocationsActions.changeCity,
    getLastModified: LastModifiedThunks.getAllAsync,
    changeLocationsLoading: LocationsActions.changeLoading
  }
)(MapPreview);
