import * as React from 'react';
import { Link } from 'react-router-dom';

export const ErrorGeneral: React.FC = () => (
  <section className="v-errorpage">
    <div className="v-errorpage__content">
      <div className="card">
        <h1 className="t-gamma s-bottom--lrg">Tražena stranica ne postoji.</h1>
        <p className="t-zeta s-bottom--lrg">
          Klikom na link se vraćate na početnu stranicu.
        </p>
        <Link to="/" className="btn btn--primary btn--med">
          Početna
        </Link>
      </div>
    </div>
  </section>
);
