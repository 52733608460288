import { createSelector } from 'reselect';
import { ApplicationState } from 'modules/redux-store';
import { Location, Company } from 'modules/locations';

const getSearchQuery = (state: ApplicationState) => state.search.query;
const getLocations = (state: ApplicationState) => state.locations.locations;

const searchLocations = createSelector(
  [getLocations, getSearchQuery],
  (content, query) =>
    content.filter(({ storeName, address, city }) =>
      applySearchParams([storeName, address, city], query)
    )
);

const zitoLocations = createSelector(
  [getLocations],
  locations => locations.filter(loc => loc.company === Company.Zito)
);

const cityFilterOptions = createSelector(
  [getLocations],
  locations => {
    let locationsOptions: Array<{ value: string; label: string }> = [];
    locations.forEach(location => {
      const exists = locationsOptions.find(
        option => option.value === location.city
      );
      if (location.city && !exists) {
        locationsOptions = [
          ...locationsOptions,
          { value: location.city, label: location.city }
        ];
      }
    });
    return locationsOptions;
  }
);

const ZitoCityFilterOptions = createSelector(
  [zitoLocations],
  locations => {
    let locationsOptions: Array<{ value: string; label: string }> = [];
    locations.forEach(location => {
      const exists = locationsOptions.find(
        option => option.value === location.city
      );
      if (location.city && !exists) {
        locationsOptions = [
          ...locationsOptions,
          { value: location.city, label: location.city }
        ];
      }
    });
    return locationsOptions;
  }
);

const previewLocationOption = createSelector(
  [getLocations],
  locations => {
    let locationsOptions: Array<{ value: string; label: Location }> = [];
    locations.forEach(location => {
      if (location) {
        locationsOptions = [
          ...locationsOptions,
          { value: location.id || '', label: location }
        ];
      }
    });
    return locationsOptions;
  }
);

const applySearchParams = (params: string[], query: string) => {
  const match = params.map(param => {
    if (!param) {
      return false;
    }

    return param.toLowerCase().includes(query.trim().toLowerCase());
  });

  return match.some(result => result);
};

export const SearchSelectors = {
  searchLocations,
  cityFilterOptions,
  ZitoCityFilterOptions,
  previewLocationOption
};
