import { Location } from 'modules/locations';

export const getContentString = (location: Location) => {
  return (
    '<div id="content" class="preview-infobox">' +
    `<h1 id="firstHeading" class="firstHeading t-epsilon t-upper s-bottom--med t-serif">${location.storeName}</h1>` +
    '<div id="bodyContent" class="preview-infobox__content">' +
    `<p class="t-eta o-60">${location.address}, ${location.postCode || ''} ${
      location.city
    }</p>` +
    `<p class="t-eta o-60">${location.company}</p>` +
    '</div>' +
    '</div>'
  );
};
